<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation"
      class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp"
                title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>

        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="/" aria-current="page">Home</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a @click="gotodownload()">Download</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="blogs">Blogs</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <div class="blog-list-row">
              <div v-for="(data, i) in bloglist" :key="i" class="blog-list-item"
                @click="goto(data.link)">
                <div class="blog-list-img">
                  <img :src="data.poster" :alt="data.alt">
                </div>
                <div class="blog-list-content">
                  <div class="blog-list-title">
                    {{ data.title }}
                  </div>
                  <div class="blog-list-descript">
                    {{ data.desc }}
                  </div>
                  <div class="blog-list-date">
                    {{ $global.formatDate(new Date().getTime(), 'dd/MM/yyyy') }}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2022 © All Rights Reserved <strong><a href="/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/blogspc.scss';

export default {
  name: 'Home',
  data () {
    return {
      pageName: 'home',
      bloglist: [
        {
          title: 'How to Add Stickers in GB WhatsApp?',
          poster: require('@/assets/blog-1.webp'),
          desc: "Adding stickers to your chats can make conversations more engaging, creative, and unique to you. Stickers are a fun way to convey emotions, humor, and even messages that might not be as expressive through text alone. With GB WhatsApp, you have the flexibility to personalize these stickers, so you can truly express yourself.",
          link: '/create-stickers-with-gbwhatsapp',
          alt: 'ceate sticker gbwhatsapp',
        },
      ],
    };
  },
  mounted () { },
  methods: {
    goto (url) {
      window.location.href = url;
    },
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/download-gbwhatsapp-apk' + params;
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
